import { makeUrl } from "@uplift-ltd/strings";
import { CASE_STUDY_URL } from "~/constants/urls";

export const caseStudyLinks: Record<string, string> = {
  "showcase/openfield": makeUrl(CASE_STUDY_URL, { caseStudySlug: "openfield" }),
  "showcase/pine-park-health": makeUrl(CASE_STUDY_URL, { caseStudySlug: "pine-park-health" }),
  "showcase/reforge": makeUrl(CASE_STUDY_URL, { caseStudySlug: "reforge" }),
  "showcase/selph": makeUrl(CASE_STUDY_URL, { caseStudySlug: "selph" }),
  "showcase/sparkwise": makeUrl(CASE_STUDY_URL, { caseStudySlug: "sparkwise" }),
  "showcase/zur-institute": makeUrl(CASE_STUDY_URL, { caseStudySlug: "zur-institute" }),
};
