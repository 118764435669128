"use client";

import cx from "clsx";
import Badge from "~/components/common/Badge";
import Image, { ImageProps } from "~/components/common/Image";
import { FadeInView } from "~/components/common/Transitions";

const DEFAULT_SHOWCASE_THUMB_HEIGHT = 224 / 2;
const DEFAULT_SHOWCASE_THUMB_WIDTH = 340 / 2;

interface ShowcaseThumbProps {
  active: boolean;
  caseStudyLink?: string;
  darkThumbnail: string;
  disabled?: boolean;
  height?: ImageProps["height"];
  lightThumbnail: string;
  onClick: () => void;
  title: string;
  width?: ImageProps["width"];
}

export const ShowcaseThumb = ({
  active,
  caseStudyLink,
  darkThumbnail,
  disabled,
  height = DEFAULT_SHOWCASE_THUMB_HEIGHT,
  lightThumbnail,
  onClick,
  title,
  width = DEFAULT_SHOWCASE_THUMB_WIDTH,
}: ShowcaseThumbProps) => {
  return (
    <FadeInView>
      <li
        className={cx("group mx-4 mb-12 shrink-0", {
          "is-active": active,
          "is-disabled": disabled,
        })}
      >
        <button
          className="relative m-[0.0625rem] block overflow-hidden rounded border opacity-100 transition-opacity group-[.is-active]:m-0 group-[.is-disabled]:cursor-not-allowed group-[.is-active]:border-2 group-[.is-active]:border-blue-500 group-[.is-disabled]:opacity-70 group-[.is-active]:outline-none hocus:m-0 hocus:border-2 hocus:border-blue-300 hocus:outline-none dark:up-bg-dark dark:group-[.is-active]:border-blue-700 dark:hocus:border-blue-900"
          data-testid="showcase-thumb-button"
          disabled={disabled}
          onClick={onClick}
          type="button"
        >
          <div className="hidden dark:block" data-testid="showcase-thumb-dark">
            <Image alt={title} height={height} src={darkThumbnail} width={width} />
          </div>
          <div className="block dark:hidden" data-testid="showcase-thumb-light">
            <Image alt={title} height={height} src={lightThumbnail} width={width} />
          </div>
          {!!caseStudyLink && (
            <Badge
              className="absolute right-0 top-0 rounded-none rounded-bl"
              size="xs"
              theme="warning"
            >
              Case Study
            </Badge>
          )}
        </button>
      </li>
    </FadeInView>
  );
};
