"use client";

import cx from "clsx";
import { useInView } from "react-intersection-observer";
import Container from "~/components/common/Container";
import Heading from "~/components/common/Heading";
import Paragraph from "~/components/common/Paragraph";
import { FadeIn } from "~/components/common/Transitions";
import SectionTitle from "./SectionTitle";

interface TechProps {
  name: string;
  type: "backend" | "database" | "frontend" | "infrastructure" | "other";
}

const Tech = ({ name, type = "other" }: TechProps) => {
  const { inView, ref } = useInView({ triggerOnce: true });
  return (
    <FadeIn inView={inView}>
      <div
        className={cx("rounded border px-4 py-2 shadow-sm", {
          "bg-gray-100 dark:border-black dark:text-gray-500 dark:up-bg-dark": type === "other",
          "bg-orange-100 border-orange-300 dark:up-bg-dark dark:border-black dark:text-orange-500":
            type === "frontend",
          "bg-purple-100 border-purple-300 dark:up-bg-dark dark:border-black dark:text-purple-500":
            type === "backend",
          "bg-red-100 border-red-300 dark:up-bg-dark dark:border-black dark:text-red-500":
            type === "database",
          "bg-yellow-100 border-yellow-400 dark:up-bg-dark dark:border-black dark:text-yellow-500":
            type === "infrastructure",
        })}
        ref={ref}
      >
        {name}
      </div>
    </FadeIn>
  );
};

interface TechnologiesProps {
  className?: string;
}

const Technologies = ({ className }: TechnologiesProps) => {
  return (
    <div className={cx("border-b", className)}>
      <Container>
        <SectionTitle className="mb-12" subtitle="The magic that makes it all happen.">
          Technologies
        </SectionTitle>
        <div className="lg:flex lg:items-center">
          <div className="-my-4 shrink-0 lg:w-1/3">
            <div className="py-4">
              <Heading className="mb-2 text-xl" tag="h3">
                Scalable
              </Heading>
              <Paragraph>
                Scale your app from very few to millions of users. Our stack is proven to grow with
                your product.
              </Paragraph>
            </div>
            <div className="py-4">
              <Heading className="mb-2 text-xl" tag="h3">
                Easy to Hire
              </Heading>
              <Paragraph>
                We choose well known tech loved by many developers, making it easy to hire for a
                growing team.
              </Paragraph>
            </div>
            <div className="py-4">
              <Heading className="mb-2 text-xl" tag="h3">
                Reliable
              </Heading>
              <Paragraph>
                We experiment with new technologies, and adopt only technologies that are proven to
                be reliable and scalable.
              </Paragraph>
            </div>
          </div>
          <div className="mt-12 grid grow grid-cols-2 gap-2 lg:mt-0 lg:w-2/3 lg:grid-cols-4 lg:gap-6 lg:pl-12">
            {/* Backend */}
            <Tech name="Python" type="backend" />
            <Tech name="Django" type="backend" />
            <Tech name="Node.js" type="backend" />
            <Tech name="GraphQL" type="backend" />

            {/* Databases */}
            <Tech name="Postgres" type="database" />
            <Tech name="MySQL" type="database" />
            <Tech name="Redis" type="database" />
            <Tech name="DynamoDB" type="database" />

            {/* Frontend */}
            <Tech name="React" type="frontend" />
            <Tech name="React Native" type="frontend" />
            <Tech name="TypeScript" type="frontend" />
            <Tech name="GraphQL / Apollo" type="frontend" />
            <Tech name="Tailwind / Emotion" type="frontend" />
            <Tech name="Gatsby" type="frontend" />
            <Tech name="Next.js" type="frontend" />
            <Tech name="Vue.js" type="frontend" />

            {/* Infrastructure */}
            <Tech name="Docker" type="infrastructure" />
            <Tech name="Kubernetes" type="infrastructure" />
            <Tech name="AWS / GCP" type="infrastructure" />
            <Tech name="...and more!" type="other" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Technologies;
