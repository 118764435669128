import { cva } from "class-variance-authority";
import cx from "clsx";

export type BadgeTheme = "disabled" | "error" | "info" | "success" | "warning";
export type BadgeSize = "md" | "sm" | "xs";

const badgeStyles = cva(["whitespace-pre-line rounded border"], {
  variants: {
    size: {
      md: "px-3 py-1",
      sm: "text-sm py-1 px-2",
      xs: "text-xs py-0.5 px-2",
    } satisfies Record<BadgeSize, string>,
    theme: {
      disabled: cx("border-gray-600 bg-gray-300 text-gray-900", "dark:bg-gray-500"),
      error: "bg-red-500 border-red-800 text-white",
      info: "bg-blue-600 border-blue-800 text-white",
      success: "bg-green-600 border-green-700 text-white",
      warning: "bg-orange-500 border-orange-500 text-orange-100",
    } satisfies Record<BadgeTheme, string>,
  },
});

export type BadgeProps = {
  children?: React.ReactNode;
  className?: string;
  size?: BadgeSize;
  theme: BadgeTheme;
  title?: string;
};

const Badge = ({ children, className, size = "md", theme, title }: BadgeProps) => {
  return (
    <span className={badgeStyles({ className, size, theme })} title={title}>
      {children}
    </span>
  );
};

export default Badge;
