"use client";

import React, { useRef, useState } from "react";
import { type ShowcaseFieldsFragment } from "~/__generated__";
import Container from "~/components/common/Container";
import SectionTitle from "../SectionTitle";
import { caseStudyLinks } from "./Showcase.constants";
import { ShowcaseThumb } from "./ShowcaseThumb";
import styles from "./Showcase.module.css";

interface ShowcaseProps {
  children: React.ReactNode;
  className?: string;
  showcases: ShowcaseFieldsFragment[];
}

const Showcase = ({ children, className, showcases }: ShowcaseProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const activeData = showcases[activeIndex];

  // should only happen on dev and maybe prod when switching pages?
  if (!activeData) {
    return null;
  }

  return (
    <div className={className}>
      <Container>
        <SectionTitle className="mb-12">Our Work</SectionTitle>
        <ul className="-mx-4 flex overflow-x-scroll">
          {showcases.map(({ darkThumbnail, lightThumbnail, name, slug }, i) => {
            const caseStudyLink = caseStudyLinks[slug];
            return (
              <ShowcaseThumb
                active={activeIndex === i}
                caseStudyLink={caseStudyLink}
                darkThumbnail={darkThumbnail}
                key={i}
                lightThumbnail={lightThumbnail}
                onClick={() => {
                  setActiveIndex(i);
                }}
                title={name}
              />
            );
          })}
        </ul>
      </Container>
      <div className="overflow-hidden border-y bg-gray-900 pb-24 pt-16 text-white dark:up-bg-dark">
        <Container>
          <div className="relative" ref={containerRef}>
            <div>{children}</div>
            <style
              dangerouslySetInnerHTML={{
                __html: `.${styles.showcaseItem}:nth-child(${
                  activeIndex + 1
                }) { opacity: 1; position: static; pointer-events: auto; }`,
              }}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Showcase;
